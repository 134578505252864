<!-- eslint-disable vue/no-mutating-props -->
<script setup>
  import { useLocale } from 'vuetify'
  import CountryFlag from 'vue-country-flag-next'
  const { current } = useLocale()
  const locale = current
</script>
<template>
       <v-data-table
            :loading="loading"
            hover
            :headers="newheaders"
            :items="table"
            @click:row="clickView"
            :items-length="meta.total"
            :items-per-page="meta.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable "
            
       >
          
          <template v-slot:item.created_at="{ item }"  >
               <span>{{ $moment(item.created_at).locale(locale).format('D MMM YYYY - HH:mm') }}</span>
          </template>
          <template v-slot:item.expire_at="{ item }"  >
               <span>{{ $moment(item.expire_at).locale(locale).format('D MMM YYYY - HH:mm') }}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
               <span>{{ $moment(item.updated_at).locale(locale).format('D MMM YYYY - HH:mm') }}</span>
          </template>
         
          <template v-slot:item.email_verified_at="{ item }">
               <span>
                    <v-icon color="success" v-if="(item.email_verified_at)">mdi-check</v-icon>
                    <v-icon color="error" v-else>mdi-cancel</v-icon>
               </span>
          </template>
          <template v-slot:item.phone_verified_at="{ item }">
               <span>
                    <v-icon color="success" v-if="(item.phone_verified_at)">mdi-check</v-icon>
                    <v-icon color="error" v-else>mdi-cancel</v-icon>
               </span>
          </template>
          <template v-slot:item.amount="{ item }">
               <span>{{item.amount}} {{item.raw.currency_symbol}}</span>
          </template>
          <template v-slot:item.active="{ item }">
               <v-icon color="green accent-4" v-if="item.active">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.points.level="{ item }">
               <v-badge
                    :color="item.points.level"
                    dot
                    left
               >
                {{item.points.level}}
               </v-badge>
          </template>
          <template v-slot:item.country="{ item }">
               <div class="text-center">
               <v-avatar size="20" color="primary" class="mx-auto text-center">
                     <v-img :src="'/img/flags/'+item.country.toLowerCase()+'.png'"></v-img>
               </v-avatar>
               </div>
          </template>
          <template v-slot:item.languages="{ item }">
               
                    <v-avatar size="20" v-for="lang in item.languages" :key="lang" color="" class="mx-1">
                         <country-flag v-if="lang=='en'" country='gbr' size='small'/>
                         <country-flag v-else :country='lang' size='small'/>
                    </v-avatar>
               
          </template>
          <template v-slot:item.avatar="{ item }">
               <div class="text-center">
                    <v-avatar 
                         :image="item.avatar" 
                         size="25" 
                         color="primary" 
                         class="mx-auto text-center">
                    </v-avatar>
               </div>
          </template>
          <template v-slot:item.logo="{ item }">
               <div class="text-center">
                    <v-img
                    height="80"
                    contain
                    :alt="item.title"
                    :src="item.logo.sizes.full"
                    ></v-img>
               </div>
          </template>
          <template v-slot:item.logo_white="{ item }">
               <div class="text-center pa-2" v-if="item.logo_white">
                    <v-img
                    height="60"
                    contain
                    :alt="item.title"
                    :src="item.logo_white.sizes.medium"
                    ></v-img>
               </div>
          </template>
          <template v-slot:item.logo_black="{ item }">
               <div class="text-center pa-2" v-if="item.logo_black">
                    <v-img
                    height="60"
                    contain
                    :alt="item.title"
                    :src="item.logo_black.sizes.medium"
                    ></v-img>
               </div>
          </template>
          <template v-slot:item.preview="{ item }">
               <div class="text-center" v-if="imagesFormat.includes(item.format)">
                    <v-img
                    height="80"
                    aspect-ratio="16/9"
                    contain
                    :alt="item.title"
                    :src="item.sizes.thumbnail"
                    ></v-img>
               </div>
               <div v-else>
                   <v-img
                    height="80"
                    aspect-ratio="16/9"
                    contain
                    :alt="item.title"
                    :src="'https://via.placeholder.com/120/?text='+item.format"
                    ></v-img>
               </div>
          </template>
          <template v-slot:item.status="{ item }">
               <v-chip :color="item.selectable.status">
                    {{ item.selectable.status }}
               </v-chip>
          </template>
          
          <template v-slot:item.thumbnail="{ item }">
               <div v-if="item[locale]">
                    <div v-if="item[locale].thumbnail" class="grey darken-4 py-3 px-3 text-center"     >
                         <v-img
                         :lazy-src="item[locale].thumbnail.sizes.thumbnail"
                         lazy
                         :src="item[locale].thumbnail.sizes.medium"
                         contain
                         class="mx-auto"
                         max-height="150px"       
                         max-width="150px"              
                         ></v-img>
                    </div>
               </div>
               <div v-else>
                    <div v-if="item['en'].thumbnail" class="grey darken-4 py-3 px-3 text-center"     >
                         <v-img
                         :lazy-src="item['en'].thumbnail.sizes.thumbnail"
                         lazy
                         :src="item['en'].thumbnail.sizes.medium"
                         contain
                         class="mx-auto"
                         max-height="150px"       
                         max-width="150px"              
                         ></v-img>
                    </div>
               </div>
          </template>
          <template v-slot:item.title-excerpt="{ item }">
               <p class="my-1" v-if="item[locale]">
                    <b>{{item[locale].title}}</b><br>
                    <small>{{item.full_slug}}</small>
               </p>
               <p class="my-1" v-else>
                    <b>{{item['en'].title}}</b><br>
                    <small>{{item.full_slug}}</small>
               </p>
          </template>
          <template v-slot:item.title-slug="{ item }">
               <p class="my-1">
                    <b>{{item.title}}</b><br>
                    <small>{{item.slug}}</small>
               </p>
          </template>
          <template v-slot:item.visible="{ item }">
               <v-icon color="green accent-4" v-if="item.visible">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.public="{ item }">
               <v-icon color="green accent-4" v-if="item.public">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.indexable="{ item }">
               <v-icon color="green accent-4" v-if="item.indexable">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.view="{ item }">
               <v-btn 
                    size="small"
                    variant="plain"
                    icon
                    color="primary"
                    @click="clickView('item.selectable',{item:item})"
               >
               <v-icon small >mdi-eye</v-icon>
               </v-btn>
          </template>
          <template v-slot:item.edit="{ item }">
               <v-btn 
                    size="small"
                    variant="plain"
                    icon
                    color="primary"
                    @click="clickEdit('item.selectable',{item:item})"
               >
               <v-icon small >mdi-pencil</v-icon>
               </v-btn>
          </template>
          <template v-slot:item.download="{ item }">
               <v-btn 
                    small
                    color="primary"
                    target="_blank"
                    :href="item[download]"
                    :disabled="!(item[download])"
                    :loading="!(item[download])"
               >
               <v-icon small class="mr-3" >mdi-download</v-icon> DOWNLOAD
               </v-btn>
          </template>
          <template v-slot:bottom>
               <div class="pt-3">
                    <v-divider></v-divider>
                    <v-container class="py-0">
                         <v-row no-gutters align="center">
                              <v-col cols="6" md="2" class="text-start order-2 order-md-1">
                                   <v-text-field 
                                   :disabled="loading"
                                   prepend-inner-icon="mdi-book-open-page-variant" 
                                   variant="plain"
                                   :max="meta.last_page"
                                   :min="1"
                                   type="number"
                                   v-model="meta.current_page">
                                   </v-text-field>
                              </v-col>
                              <v-col cols="12" md="7" class="order-1 mx-auto order-md-2">
                                   <v-pagination
                                   v-model="meta.current_page"
                                   :length="meta.last_page"
                                   :total-visible="5"
                                   :disabled="loading" 
                                   @update:model-value="page(meta.current_page)"
                                   ></v-pagination>
                              </v-col>
                              <v-col cols="6" md="2" class="text-end px-5 order-3 order-md-3">
                                   {{ meta.from }} - {{ meta.to }} of {{ meta.total }} Items
                              </v-col>
                         </v-row>
                    </v-container>
               </div>
          </template>
        </v-data-table>
</template>

<script>
export default {
     name:"PaginatedTable",
     props:{
          name:{
               type: String,
               default: "single"
          },
          table: {
               type: Array,
               required: true,
          },
          headers: {
               type: Array,
          },
          edit: {
               type: Boolean,
               default: false
          },
          view: {
               type: Boolean,
               default: false
          },
          download: {
               type: String,
               default: null
          },
          id: {
               type: String,
                default: 'id'
          },
          meta:{
               type: Object,
               required: true
          },
          loading:{
               type: Boolean,
               default: true,
          },
     },
     data: () => ({
          imagesFormat: ['webp', 'png', 'jpg', 'svg'],
          datesKey: ['date_of_birth','start','end'],
          newheaders: [],
          datesTimeKey: ['created_at','expire_at','updated_at','email_verified_at','phone_verified_at','confirmed_at','issued_on','posted_at']
     }),
     beforeMount(){
          this.getHeaders()
     },
     methods:{
          getHeaders(){
               if(!this.headers){
                    var keys = Object.keys(this.table);
                    keys.forEach(head => {
                         head  = {text:head, value: head,sortable: false}
                         //console.log(head)
                         this.newheaders.push(head)
                    });
               }else{
                    this.newheaders = this.newheaders.concat(this.headers);
               }
               
               if(this.view){
                    this.newheaders.push({title:'view', key: 'view', sortable: false, width: '5%', align: 'center'})
               }
               if(this.edit){
                    this.newheaders.push({title:'edit', key: 'edit', sortable: false, width: '5%', align: 'center'})
               }
               if(this.download){
                    this.newheaders.push({title:'download', key: 'download', sortable: false, width: '5%', align: 'center'})
               }

               if(this.newheaders.length >= 1){
                    this.newheaders.forEach((item) => {
                         item.text = item.text;
                    });
               }

               console.log('this.newheaders',this.newheaders)
               this.newheaders;
          },
          page(page){
               console.log("META",page)
               this.meta.current_page = page
               this.$emit('update:meta', this.meta)
               this.$emit('change', this.meta)
          },
          isString(val){
               return typeof val === 'string' || val instanceof String
          },
          isObject(val){
               return (!!val) && (val.constructor === Object);
          },
          isArray(val){
               return (!!val) && (val.constructor === Array)
          },
          isDate(key){
               return this.datesKey.includes(key)
          },
          isDateTime(key){
               return this.datesTimeKey.includes(key)
          },
          clickView(event, item){
               console.log("clickView", item.item);
               this.$emit('open',item.item)
          },
          clickEdit(event, item){
               console.log("clickEdit", item.item);
               this.$emit('edit',item.item)
          }
     }
  }
</script>
<style >
.striped tr:nth-child(even) {background-color:  #0000000D;}
.striped tr:nth-child(odd) {background-color: #FFFFFF; }
</style>