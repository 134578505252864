import apiClient from '@/plugins/apiClient'
let basePath = "/cms/settings";
import qs from 'qs';

export default {
     namespaced: true,
     state:{
          list: [],
          single: null,
          errors: {},
          message: {},
          redirects: {},
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          filters: {
               wheres : []
          }
     },
     getters:{
          list: state => state.list,
          single: state => state.single,
          message: state => state.message,
          errors: state => state.errors,
          meta: state => state.meta,
          filters: state => state.filters,
          redirects: state => state.redirects,
     },
     mutations:{
        error(state, err) {
            console.log('mutation error',err);
            state.errors = err.response.data
        },
        setList(state, resp) {
            console.log('mutation setList',resp);
            state.list = resp.data
            state.errors = {}
        },
        setMeta(state, resp){
          console.log('mutation setMeta',resp.meta);
          state.meta = resp.meta
        },
        setRedirects(state, resp){
          console.log('mutation setRedirects',resp.data);
          state.redirects = resp.data
          state.errors = {}
        },
        setFilters(state, resp){
          console.log('mutation setFilters',resp.filters);
          state.filters = resp.filters
        },
        setSingle(state, resp){
          console.log('mutation setSingle',resp.data);
          state.single = resp.data
          state.errors = {}
        }
     },
     actions:{
          getList({commit, state}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET LIST :'+data)
                    console.log('GET LIST filters:', state.filters)
                    var params =  qs.stringify(state.filters, { arrayFormat: 'indices' })
                    apiClient.get(basePath+'?page='+state.meta.current_page+'&'+params).then(resp => {
                         commit('setList', resp.data);
                         commit('setMeta', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getSingle({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('GET Single:'+data.id)
                    apiClient.get(basePath+'/'+data.id).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateSingle({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('Update Single :'+data.id)
                    apiClient.patch(basePath+'/'+data.id+'/update',data).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('Update SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createSingle({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('Create Single :'+data)
                    apiClient.post(basePath+'/create',data).then(resp => {
                         commit('setSingle', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('Create SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          applicationUpdate({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('Create Single :'+data)
                    apiClient.post(basePath+'/app-update',data).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('Create SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          sitemapBuilder({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('upadete Sitemap :'+data)
                    apiClient.post(basePath+'/sitemap-builder',data).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('Create SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getRedirects({commit}){
               return new Promise((resolve, reject) => {
                    console.log('get Redirects ')
                    apiClient.get(basePath+'/redirects').then(resp => {
                         commit('setRedirects', resp);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('Create SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          postRedirects({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('post Redirects :'+data)
                    apiClient.post(basePath+'/redirects', data).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('Create SINGLE', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setAsSingle({state}, data){
               state.single = data;
          },
          setMeta({commit}, data){
               console.log('action meta',data);
               commit('setMeta',{meta:data});
               // commit('success', true);
          },
          setFilters({commit}, data){
               console.log('action setFilters',data);
               commit('setFilters',{filters:data});
          }
     },
}